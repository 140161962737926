// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loading_loader__mFVh\\+{border:5px solid #fff;border-radius:50%;border-top:5px solid #007759;border-right:5px solid #007759;border-bottom:5px solid #007759;width:30px;height:30px;animation:2s linear infinite Loading_spin__V5amq}@keyframes Loading_spin__V5amq{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}`, "",{"version":3,"sources":["webpack://./src/components/ui/Loading.module.css"],"names":[],"mappings":"AAAA,wBAAQ,qBAAqB,CAAC,iBAAiB,CAAC,4BAA4B,CAAC,8BAA8B,CAAC,+BAA+B,CAAC,UAAU,CAAC,WAAW,CAA2C,gDAAiC,CAA+F,+BAAgB,GAAG,mBAAmB,CAAC,KAAK,wBAAwB,CAAC","sourcesContent":[".loader{border:5px solid #fff;border-radius:50%;border-top:5px solid #007759;border-right:5px solid #007759;border-bottom:5px solid #007759;width:30px;height:30px;-webkit-animation:2s linear infinite spin;animation:2s linear infinite spin}@-webkit-keyframes spin{0%{-webkit-transform:rotate(0)}100%{-webkit-transform:rotate(360deg)}}@keyframes spin{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `Loading_loader__mFVh+`,
	"spin": `Loading_spin__V5amq`
};
export default ___CSS_LOADER_EXPORT___;
