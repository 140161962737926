import React from 'react'
import { NavLink } from 'react-router-dom'
import classes from './MainNavigation.module.css'
// import { GiHumanPyramid } from 'react-icons/gi'
import { TbMenu2 } from "react-icons/tb";
import logo from '../../JantamatLogo.svg';

const MainNavigation = () => {  

    const handleNavbarToggler = (event) => {
        let divElement = document.getElementById("navbarTogglerDemo01")
        divElement.setAttribute("class", "navbar-collapse collapse")
    }

  return (
    <nav className={`navbar navbar-expand-lg fixed-top ${classes.header}`} >
        <div className="container-fluid">
                <NavLink to="/" className="navbar-brand">
                    <img src={logo} alt="Logo" width="50" height="44"  className="d-inline-block" />
                    <span className={classes.logoText} onClick={handleNavbarToggler}>JantaMat</span>
                </NavLink>
                <button type="button" className={classes.togglerbtn} data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span><TbMenu2 style={{width:"30", height:"50", color:'white'}}/></span>
                </button>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                <ul className={`navbar-nav ms-auto mb-2 mb-lg-0 ${classes.navbarNav}`} >
                    <li className="nav-item">
                        <NavLink onClick={handleNavbarToggler} className={`nav-link`} to="/" style={({ isActive }) => isActive ? {color:'#ffd967', fontWeight:700} : { color: 'white'}}>Home</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink onClick={handleNavbarToggler} className={`nav-link`} to="/add-review" style={({ isActive }) => isActive ? {color:'#ffd967', fontWeight:700} : { color: 'white'}}>Add Review</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink onClick={handleNavbarToggler} className={`nav-link`} to="/reviews" style={({ isActive }) => isActive ? {color:'#ffd967', fontWeight:700} : { color: 'white'}}>Reviews</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink onClick={handleNavbarToggler} className={`nav-link`} to="/help" style={({ isActive }) => isActive ? {color:'#ffd967', fontWeight:700} : { color: 'white'}}>Help</NavLink>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
  )
}

export default MainNavigation