import React from 'react'
import classes from './Loading.module.css'

const Loading = (props) => {
  return (
    <div className={`${classes.loader} ${props.className}`} style={props.style}>
        
    </div>
  )
}

export default Loading