import React from 'react'
import { Outlet } from 'react-router-dom'
import MainNavigation from '../components/navigation/MainNavigation'

const SearchLayout = () => {
  return (
    <>
        <MainNavigation />
        <main>
            <Outlet />
        </main>
        {/* footer tag */}
    </>
  )
}

export default SearchLayout