import React, {useState, useCallback} from 'react'
import BASE_URL from '../services'

const AuthContext = React.createContext({
    msg:'',
    load:false,
    storeReviewedData:[],
    fetchReviewedData:() => {},

    storedCountryData:[],
    fetchCountryData:() => {},

})

export const AuthContextProvider=(props)=>{
    const [message, setIsMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const [reviewedData, setReviewedData] = useState([])
    const [countryData, setCountryData] = useState([])

    const laoding = isLoading
    const Messages = message

    const reviewedDatas = reviewedData
    const countriesData = countryData

    /* *********************** FETCH REVIEWED DATA ************************* */
    const fetchReviewedDataHandler = useCallback(async ()=>{
        try {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/review/reviewed-list`)
        if(!response.ok){
            throw new Error('Something went wrong !')
        }
        const data = await response.json()
        const mappedReviewedData = data.reviewedList.map((data)=>{
            return {
                id:data.id,
                slug:data.slug, 
                name:data.name, 
                profession:data.profession, 
                photo:data.photo,
                rating:data.rating, 
                vote:data.vote,
                comment:data.comment,  
            }
        })
        setReviewedData(mappedReviewedData)
        } catch (error) { 
            const msg = error.message
            setIsMessage(msg)
        }
        setIsLoading(false)
    }, [])


    /* *********************** FETCH REVIEWED DATA ************************* */
    const fetchCountryDataHandler = useCallback(async ()=>{
        try {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/country-state-city/country`)
        if(!response.ok){
            throw new Error('Something went wrong !')
        }
        const data = await response.json()
        const mappedCountryData = data.countries.map((data)=>{
            return {
                id:data._id,
                name:data.name,
                isocode:data.isocode,
                countrycode:data.countrycode,
            }
        })
        setCountryData(mappedCountryData)
        } catch (error) { 
            const msg = error.message
            setIsMessage(msg)
        }
        setIsLoading(false)
    }, [])

   


    const contextValue = {
        msg:Messages,
        load:laoding,

        storeReviewedData:reviewedDatas,
        fetchReviewedData:fetchReviewedDataHandler,

        storedCountryData:countriesData,
        fetchCountryData:fetchCountryDataHandler,
    }

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    )

}

export default AuthContext
