import React from 'react'
import { Link } from 'react-router-dom'
import classes from './LinkButton.module.css'

const LinkButton = (props) => {
  return (  
    <Link to={props.url} className={`${props.className} ${classes.linkTransparent}`} onClick={props.onClick} style={props.style}>{props.value}</Link>
  )
}

export default LinkButton