// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LinkButton_linkDark__SfLbD,.LinkButton_linkTransparent__495-x{color:#226e67;outline:0;text-decoration:none;margin:4px}.LinkButton_linkDark__SfLbD{padding:5px 20px;border:1px solid #226e67;border-radius:50px}.LinkButton_linkTransparent__495-x{padding:5px 15px 7px;font-size:17px;border:1px solid #226e67;border-radius:50px}.LinkButton_linkTransparent__495-x:hover{background-color:#226e67;color:#fff}`, "",{"version":3,"sources":["webpack://./src/components/ui/LinkButton.module.css"],"names":[],"mappings":"AAAA,+DAA2B,aAAa,CAAC,SAAS,CAAC,oBAAoB,CAAC,UAAU,CAAC,4BAAU,gBAAgB,CAAC,wBAAwB,CAAC,kBAAkB,CAAC,mCAAiB,oBAAoB,CAAC,cAAc,CAAC,wBAAwB,CAAC,kBAAkB,CAAC,yCAAuB,wBAAwB,CAAC,UAAU","sourcesContent":[".linkDark,.linkTransparent{color:#226e67;outline:0;text-decoration:none;margin:4px}.linkDark{padding:5px 20px;border:1px solid #226e67;border-radius:50px}.linkTransparent{padding:5px 15px 7px;font-size:17px;border:1px solid #226e67;border-radius:50px}.linkTransparent:hover{background-color:#226e67;color:#fff}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkDark": `LinkButton_linkDark__SfLbD`,
	"linkTransparent": `LinkButton_linkTransparent__495-x`
};
export default ___CSS_LOADER_EXPORT___;
