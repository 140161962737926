import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { lazy, Suspense } from 'react'
import RootLayout from './pages/RootLayout'
import SearchLayout from './pages/SearchLayout'
import HelpLayout from './pages/HelpLayout'

import ErrorPage from './pages/ErrorPage'
import Loading from  './components/ui/Loading'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'

const HomePage = lazy(() => import('./pages/HomePage'))
const SearchPage = lazy(() => import('./pages/SearchPage'))
const HelpPage = lazy(() => import('./pages/HelpPage'))

const ReviewForm = lazy(() => import('./components/review/ReviewForm'))
const SearchReview = lazy(() => import('./components/review/SearchReview'))
const DetailView = lazy(() =>  import('./components/review/DetailView'))
const EditReview = lazy(() =>  import('./components/review/EditReview'))
const ViewList = lazy(() =>   import('./components/review/ViewList'))


const router = createBrowserRouter([ 
  {path: '/', element: <RootLayout />,
    children:[
      {path: '/', element: <Suspense fallback={<Loading />}><HomePage /></Suspense>},
      {path: '/add-review', element: <Suspense fallback={<Loading />}><ReviewForm /></Suspense>},
      {path: '/add-review/reviewed/:slug', element: <Suspense fallback={<Loading />}><EditReview /></Suspense>},
      {path: '/reviews', element: <Suspense fallback={<Loading />}><ViewList /></Suspense>},
      {path: '/view/detail-view/:slug', element: <Suspense fallback={<Loading />}><DetailView /></Suspense>}
    ],
    errorElement:<ErrorPage />
  },

  {path: '/search', element: <Suspense fallback={<Loading />}><SearchLayout /></Suspense>,
    children:[
      {path: '/search', element: <Suspense fallback={<Loading />}><SearchPage /></Suspense>},
      {path: '/search/search-query?', element: <Suspense fallback={<Loading />}><SearchReview /></Suspense>}
    ],
    errorElement:<ErrorPage />
  },

  {path: '/help', element: <Suspense fallback={<Loading />}><HelpLayout /></Suspense>,
    children:[
      {path: '/help', element: <Suspense fallback={<Loading />}><HelpPage /></Suspense>},
    ],
    errorElement:<ErrorPage />
  },
 ])


function App() {
  return <RouterProvider router={router} fallbackElement={<Loading />} />
}

export default App;
